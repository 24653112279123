export class Carousel {
    constructor(wrapper, previousButton, nextButton) {
        this.wrapper = wrapper;
        this.timerId = null;
        this.next = null;
        this.previousButton = previousButton;
        this.nextButton = nextButton;
    }

    autoSlide() {
        this.wrapper.append(this.wrapper.firstElementChild);
    }

    setActiveSlide(data) {
        if (data === 'prev') {
            this.wrapper.prepend(this.wrapper.lastElementChild);
        } else {
            this.wrapper.append(this.wrapper.firstElementChild);
        }
    }

    moveTo(data) {
        clearInterval(this.timerId);
        this.setActiveSlide(data);
        this.timerId = setInterval(() => this.autoSlide(), 5000);
    }

    initCarousel() {
        this.previousButton.addEventListener('click', e => this.moveTo('prev'));
        this.nextButton.addEventListener('click', e => this.moveTo('next'));
        this.timerId = setInterval(() => this.autoSlide(), 5000);
    }
}
