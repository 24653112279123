export class Slider {
    constructor(wrapper, pagination, previousButton, nextButton) {
        this.current = null;
        this.middle = null;
        this.wrapper = wrapper;
        this.pagination = pagination;
        this.currentPaging = null;
        this.timerId = null;
        this.previuos = null;
        this.next = null;
        this.previousButton = previousButton;
        this.nextButton = nextButton;
    }

    autoSlide() {
        this.currentPaging++;
        if (this.currentPaging > this.wrapper.children.length - 1) this.currentPaging = 0;
        this.setActivePage(this.currentPaging);
        this.wrapper.append(this.wrapper.firstElementChild);
    }

    showPagination(i) {
        const div = document.createElement('div');
        this.pagination.append(div);
        this.pagination.children[i].setAttribute('number', i);
        this.pagination.children[i].addEventListener('click', e => this.moveTo(e));
        this.setActivePage(this.middle - 1);
    }

    setArrows() {
        this.previuos = this.currentPaging === 0 ? this.wrapper.children.length - 1 : this.currentPaging - 1;
        this.next = this.currentPaging === this.wrapper.children.length - 1 ? 0 : this.currentPaging + 1;
        this.previousButton.setAttribute('number', this.previuos);
        this.nextButton.setAttribute('number', this.next);
    }

    setActivePage(item) {
        this.currentPaging = item ?? Number(this.wrapper.children[this.current].getAttribute('number'));
        for (let i = 0; i < this.pagination.children.length; i++) {
            this.pagination.children[i].classList.remove('active');
            if (i === this.currentPaging) {
                this.pagination.children[this.currentPaging].classList.add('active');
            }
        }
        this.setArrows();
    }

    setActiveSlide() {
        const elem = this.middle - this.current - 1;
        this.setActivePage();
        for (let i = 0; i < Math.abs(elem); i++) {
            if (elem > 0) {
                this.wrapper.prepend(this.wrapper.lastElementChild);
            } else {
                this.wrapper.append(this.wrapper.firstElementChild);
            }
        }
    }

    moveTo(e) {
        clearInterval(this.timerId);
        for (let i = 0; i < this.wrapper.children.length; i++) {
            if (e.currentTarget.getAttribute('number') === this.wrapper.children[i].getAttribute('number')) {
                this.current = i;
            }
        }
        this.setActiveSlide(this.wrapper, this.pagination);
        this.timerId = setInterval(() => this.autoSlide(), 4000);
    }

    initSlider() {
        this.middle = Math.round(this.wrapper.children.length / 2);
        this.current = this.middle;

        for (let i = 0; i < this.wrapper.children.length; i++) {
            this.showPagination(i);
            this.wrapper.children[i].setAttribute('number', i);
        }
        this.previousButton.addEventListener('click', e => this.moveTo(e));
        this.nextButton.addEventListener('click', e => this.moveTo(e));
        this.timerId = setInterval(() => this.autoSlide(), 4000);
    }
}
