import Router from './createRouter';
import { templateRenderer, Slider } from '../utils';

export default (path) => {
    const startTemplate = templateRenderer('start');

    return {
        [path]: {
            uses: _ => {
                Router.updatePageLinks();
            },
            hooks: {
                before: (done) => {
                    startTemplate.mount();
                    const wrapper = document.querySelector('.slider-wrapper');
                    const pagination = document.querySelector('.slider-pagination');
                    const prevButton = document.querySelector('.slider-prev');
                    const nexButton = document.querySelector('.slider-next');
                    const slider = new Slider(wrapper, pagination, prevButton, nexButton);
                    slider.initSlider();
                    done();
                },
                leave: _ => {
                    startTemplate.unmount();
                }
            }
        }
    };
};
