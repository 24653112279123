export const trialInfo = (duration, price) => {
    const box = document.querySelector('.subscription-legal p');
    const about = document.querySelector('.about-list:last-of-type div');
    const header = document.querySelector('.card-heading span');
    const cardTrialInfo = document.querySelector('.card-text__medium span');
    const cardBenifitsTrialList = document.querySelector('.card-benefits-list span');
    if (header) {
        header.textContent = `${duration ? 'for free' : ''}`;
    }
    if (cardTrialInfo) {
        cardTrialInfo.textContent = duration;
    }
    if (cardBenifitsTrialList) {
        cardBenifitsTrialList.textContent = duration;
    }
    box.innerHTML = `By submitting your payment information, you agree that at the end of your free trial, we will automatically charge your card $${price} each ${price > 15 ? 'year' : 'month'} until you cancel. See our <a class="payment-links" href="https://www.apalon.com/terms_of_service.html#d4" target="_blank" rel="nofollow">Cancellation Policy</a> or to cancel, go to the My Profile page or email us at <a class="payment-links" href="mailto:support@apalon.com">support@apalon.com</a>.`;
    about.innerHTML = `${duration ? `Start with a ${duration}-day free trial and see what changes you can make.` : ''} If you don't like Productive, you can cancel anytime.`;
    document.querySelector('.card').classList.add(`${duration ? 'trial-true' : 'trial-false'}`);
};
