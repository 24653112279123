import {
    AUTHORIZED,
    DENIED,
    NOT_DETERMINED
} from '../constants';

let checkboxValue = false;

const stringGDPR = 'We may use email to communicate with you about your account.';
const stringNotGDPR = 'We use email to communicate with you about your account and to send you special offers and promotions.';

export const renderConsent = response => {
    const checkbox = document.querySelector('input[type=checkbox]');
    const consent = document.querySelector('.consent-box');
    const consentText = document.querySelector('.consent-disclaimer span');
    const consentBorder = document.querySelector('.consent-disclaimer');
    if (response.data?.data_collection_consent === 'required') {
        consent.style.display = 'flex';
        checkbox.setAttribute('data-consent', DENIED);
        checkbox.checked = checkboxValue;
        consentText.innerHTML = stringGDPR;
        consentBorder.classList.add('separator');
    } else {
        consentText.innerHTML = stringNotGDPR;
        consent.style.display = 'none';
        checkbox.setAttribute('data-consent', NOT_DETERMINED);
        consentBorder.classList.remove('separator');
    }
    checkbox.addEventListener('change', e => {
        e.target.checked ? checkboxValue = true : checkboxValue = false;
    });
};

export const getConsent = () => {
    const checkbox = document.querySelector('input[type=checkbox]');
    const consent = checkbox.dataset.consent;
    if (consent !== NOT_DETERMINED) {
        if (checkbox.checked) {
            // eslint-disable-next-line no-undef
            gtag('event', 'Check box checked');
            return AUTHORIZED;
        }
        return DENIED;
    }
    return consent;
};
