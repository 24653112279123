import jwtDecode from 'jwt-decode';
import Router from './createRouter';

import { clearPopup, imageObserver, modalWindow, showPopup, templateModal, Carousel } from '../utils';
import billingSDK from '../billing';
import authService from '../auth';

const nav = document.querySelector('.nav');
const navWrapper = document.querySelector('.nav-wrapper');
const navUser = document.querySelector('.nav-user');
const signOutButton = document.querySelector('.sign-out-button');
const upgradePlanButton = document.querySelector('.upgrade-plan-button');
const userMenu = document.querySelector('.nav-account-menu');
const imagesToChange = document.querySelectorAll('.feature-picture.hidden');

const popupSignOut = templateModal('popup_signout');
const signOutModal = e => {
    e.preventDefault();
    modalWindow(popupSignOut).show().once('dismiss', (modal, ev) => {
        if (ev.target.classList.contains('button_border-yellow')) {
            showPopup();
            setTimeout(() => {
                clearPopup();
                authService.clear();
                userMenu.classList.remove('opened');
                nav.classList.replace('logged-in', 'logged-out');
                nav.classList.remove('opened');
                gtag('event', 'Account sign out');
            }, 300);
        }
    });
};

export default path => ({
    [`${path}`]: {
        uses: () => {
            Router.updatePageLinks();
        },
        hooks: {
            before: done => {
                gtag('event', 'Home page opened'); // eslint-disable-line no-undef
                const page = document.querySelector('.page');
                page.removeAttribute('hidden');
                navWrapper.classList.add('loading');
                const wrapper = document.querySelector('.carousel-wrapper');
                const prevButton = document.querySelector('.carousel-left');
                const nexButton = document.querySelector('.carousel-right');
                const carousel = new Carousel(wrapper, prevButton, nexButton);
                carousel.initCarousel();
                billingSDK.getPaymentProfile().then(
                    response => {
                        navWrapper.classList.remove('loading');
                        const { data, message } = response;
                        if (message) {
                            nav.classList.replace('logged-in', 'logged-out');
                        } else {
                            nav.classList.replace('logged-out', 'logged-in');
                            document.querySelector('.nav-user span').innerHTML = authService.getUserName(jwtDecode);
                            if (data.payment_customer_id) {
                                upgradePlanButton.style.display = 'none';
                            } else {
                                upgradePlanButton.style.display = 'flex';
                            }
                        }
                    }
                );
                signOutButton.addEventListener('click', signOutModal);
                navUser.addEventListener('click', (e) => {
                    e.currentTarget.classList.toggle('opened');
                    document.querySelector('.nav-account-menu').classList.toggle('opened');
                });
                imagesToChange.forEach((image) => imageObserver.observe(image));
                done();
            },
            leave: () => {
                const page = document.querySelector('.page');
                page.setAttribute('hidden', true);
            }
        }
    }
});
