import serialize from 'form-serialize';
import { submitValidate, templateModal, templateRenderer, validateForm, modalWindow } from '../utils';
import ROUTES from './constants';
import Router from './createRouter';
import authService from '../auth';

const popupDone = templateModal('popup-done');
const doneModal = email => {
    modalWindow(popupDone)
        .show()
        .once('shown', () => {
            const text = document.querySelector('.popup-box__text');
            text.innerHTML = `We’ve sent an email with instructions to <strong>${email}</strong>`;
        })
        .once('dismiss', () => {
            setTimeout(() => {
                Router.navigate(ROUTES.SIGNIN);
            }, 300);
        });
};

export default path => {
    const forgotTemplate = templateRenderer('form-forgot');

    return {
        [path]: {
            uses: () => {
                const inputs = document.querySelectorAll('form input');
                const form = document.querySelector('form');
                let email = null;
                const submitCallback = () => {
                    const data = serialize(form, { hash: true });
                    email = data.email;
                    authService.forgotPassword({ email: email }, () => doneModal(email));
                };
                form.addEventListener('submit', (e) => {
                    e.preventDefault();
                    submitValidate(submitCallback);
                });
                inputs.forEach(item => item.addEventListener('input', () => validateForm('Forgot password')));
            },
            hooks: {
                before: done => {
                    forgotTemplate.mount();
                    done();
                },
                leave: () => {
                    forgotTemplate.unmount();
                }
            }
        }
    };
};
