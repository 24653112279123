import { templateRenderer } from '../utils';
import Router from './createRouter';

export default path => {
    const congratsTemplate = templateRenderer('congrats');

    return {
        [path]: {
            uses: () => {
                Router.updatePageLinks();
            },
            hooks: {
                before: done => {
                    congratsTemplate.mount();
                    done();
                },
                leave: () => {
                    congratsTemplate.unmount();
                }
            }
        }
    };
};
