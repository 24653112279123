export const validateForm = source => {
    const form = document.querySelectorAll('form input[type=text], form input[type=password]');
    let valid = true;
    form.forEach(item => {
        item.classList.remove('invalid');
        if (item.value.length === 0) {
            valid = false;
        } else {
            const type = item.getAttribute('type') === 'password' ? 'password' : 'email';
            // eslint-disable-next-line no-undef
            gtag('event', `${source} ${type} entered`);
        };
    });
    document.querySelector('.form-error').style.display = 'none';
    const submit = document.querySelector('button[type=submit]');
    submit.setAttribute('disabled', true);
    if (valid) submit.removeAttribute('disabled');
};

export const submitValidate = (cb, source) => {
    const form = document.querySelectorAll('form input[type=text], form input[type=password]');
    let valid = true;
    form.forEach(item => {
        item.classList.remove('invalid');
        if (!item.validity.valid) {
            item.classList.add('invalid');
            valid = false;
        }
    });
    document.querySelector('.form-error').style.cssText = `display: ${valid ? 'none' : 'block'}`;
    if (valid) {
        cb();
        return;
    }
    // eslint-disable-next-line no-undef
    if (source) gtag('event', `${source} failed`);
};
