import Router from './createRouter';
import { templateRenderer } from '../utils';
import { ROUTES } from './index';

let timerId = null;

export default (path) => {
    const quizStartTemplate = templateRenderer('quiz-start');
    const quizFirstTemplate = templateRenderer('quiz-1');
    const quizSecondTemplate = templateRenderer('quiz-2');
    const quizThirdTemplate = templateRenderer('quiz-3');
    const quizFourthTemplate = templateRenderer('quiz-4');

    return {
        [path]: {
            uses: () => {
                Router.updatePageLinks();
            },
            hooks: {
                before: (done) => {
                    quizStartTemplate.mount();
                    done();
                },
                leave: () => {
                    quizStartTemplate.unmount();
                }
            }
        },
        [`${path}/1`]: {
            uses: (params, query) => {
                const el = document.querySelectorAll('.card-select .card-option');
                if (query) {
                    document.querySelector('.card-nav__step').innerHTML = 'Step 2 <span>of 3</span>';
                    document.querySelector('.card-progress').classList.add('active2');
                    document.querySelector('.card-nav__skip').setAttribute('href', '#quiz/3?ab');
                }
                el.forEach(item => item.addEventListener('click', e => {
                    e.preventDefault();
                    const answer = e.currentTarget.children[0].innerText;
                    if (query) {
                        Router.navigate(`${path}/3?ab`);
                    } else {
                        Router.navigate(`${path}/2`);
                    }
                    // eslint-disable-next-line no-undef
                    gtag('event', 'Quiz step 1 - Option clicked',
                        {
                            eventCategory: 'quiz',
                            eventLabel: 'Quiz step 1 - Option clicked',
                            Answer: answer
                        });
                }));
            },
            hooks: {
                before: (done) => {
                    quizFirstTemplate.mount();
                    done();
                },
                leave: () => {
                    quizFirstTemplate.unmount();
                }
            }
        },
        [`${path}/2`]: {
            uses: () => {
                const el = document.querySelectorAll('.card-select .card-option');
                el.forEach(item => item.addEventListener('click', e => {
                    e.preventDefault();
                    const answer = e.currentTarget.children[0].innerText;
                    Router.navigate(`${path}/3`);
                    // eslint-disable-next-line no-undef
                    gtag('event', 'Quiz step 2 - Option clicked',
                        {
                            eventCategory: 'quiz',
                            eventLabel: 'Quiz step 2 - Option clicked',
                            Answer: answer
                        });
                }));
            },
            hooks: {
                before: (done) => {
                    quizSecondTemplate.mount();
                    done();
                },
                leave: () => {
                    quizSecondTemplate.unmount();
                }
            }
        },
        [`${path}/3`]: {
            uses: () => {
                const el = document.querySelectorAll('.card-select .card-option');
                el.forEach(item => item.addEventListener('click', e => {
                    e.preventDefault();
                    const answer = e.currentTarget.children[0].innerText;
                    Router.navigate(`${path}/4`);
                    // eslint-disable-next-line no-undef
                    gtag('event', 'Quiz step 3 - Option clicked',
                        {
                            eventCategory: 'quiz',
                            eventLabel: 'Quiz step 3 - Option clicked',
                            Answer: answer
                        });
                }));
            },
            hooks: {
                before: (done) => {
                    quizThirdTemplate.mount();
                    done();
                },
                leave: () => {
                    quizThirdTemplate.unmount();
                }
            }
        },
        [`${path}/4`]: {
            uses: () => {
                Router.navigate(ROUTES.SIGNUP);
            },
            hooks: {
                before: (done) => {
                    quizFourthTemplate.mount();
                    timerId = setTimeout(done, 3000);
                },
                leave: () => {
                    if (timerId) clearTimeout(timerId);
                    quizFourthTemplate.unmount();
                }
            }
        }
    };
};
