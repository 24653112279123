import Modal from 'modal-vanilla';

const setScrollTop = () => {
    window.scrollTop = 0;
    window.document.body.scrollTop = 0;
    window.document.documentElement.scrollTop = 0;
};

function closeModalBeforeLeave() {
    if (document.body.classList.contains('modal-open')) {
        document.body.classList.remove('modal-open');
        document.querySelector('.modal').remove();
    }
};

export const templateRenderer = templateId => {
    const page = document.querySelector('.screen');
    const template = document.getElementById(templateId).content;
    let wrapper;
    const mount = () => {
        wrapper = document.createElement('div');
        wrapper.setAttribute('class', 'screen-content');
        wrapper.append(template.cloneNode(true));
        page.prepend(wrapper);
        setScrollTop();
    };

    const unmount = () => {
        closeModalBeforeLeave();
        wrapper.remove();
        wrapper = null;
    };

    return { mount, unmount };
};

const defaultParam = {
    backdrop: false,
    keyboard: false,
    title: false,
    header: false,
    footer: false,
    buttons: false,
    headerClose: false,
    construct: false
};

export const modalWindow = template => {
    return new Modal({
        content: template,
        ...defaultParam
    });
};

export const templateModal = templateId => {
    const template = document.getElementById(templateId).content;
    const wrapper = document.createElement('div');
    wrapper.appendChild(template);
    return wrapper;
};
