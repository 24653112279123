import { addDays, format, getTime, isPast, parseISO } from 'date-fns';
import jwtDecode from 'jwt-decode';

import authService from '../auth';
import billingSDK from '../billing';
import { templateRenderer, clearPopup, showPopup, templateModal, modalWindow } from '../utils';
import Router from './createRouter';
import ROUTES from './constants';
import { TIME_FORMAT, APP_STORE, GOOGLE_PLAY, WEB_SITE, ANNUAL_PLAN, MONTHLY_PLAN, MONTHLY_PRICE, ANNUAL_PRICE, DAYS_TRIAL } from '../constants';

const setFreeUser = () => {
    document.querySelectorAll('.free').forEach(item => item.classList.add('show'));
};

const dropNodes = () => {
    // [TODO] should be rewrite
    document.querySelectorAll('.pmob').forEach(item => item.classList.remove('show'));
    document.querySelectorAll('.paid').forEach(item => item.classList.remove('show'));
    document.querySelectorAll('.pweb').forEach(item => item.classList.remove('show'));
    document.querySelectorAll('.puweb').forEach(item => item.classList.remove('show'));
    document.querySelectorAll('.pdweb').forEach(item => item.classList.remove('show'));
    const parentNode = document.querySelector('.plan-info');
    while (parentNode.firstChild) parentNode.removeChild(parentNode.firstChild);
};

const setPaidUser = subscriptions => {
    const isSubscription = subscriptions.length > 1;
    const moreThanOne = isSubscription ? 'display: block' : 'display: none';
    document.querySelector('.card-hr').style.cssText = moreThanOne;
    document.querySelector('.card-features').style.cssText = `${isSubscription && 'display: none'}`;
    subscriptions.forEach(item => {
        const { data, productId } = item;
        const isMobile = data.paymentProcessor !== 'braintree';
        document.querySelector('.plan-unsubscribe button').setAttribute('data-id', data.transactionId);
        document.querySelectorAll('.pmob').forEach(item => isMobile && item.classList.add('show'));
        document.querySelectorAll('.paid').forEach(item => item.classList.add('show'));
        const parentWebNode = document.querySelector('.plan-info.web');
        const parentMobileNode = document.querySelector('.plan-info.mobile');
        const div = document.createElement('div');
        const isAnnual = productId.indexOf('y_') !== -1;
        const isActive = data.cancelReason === undefined;
        document.querySelectorAll('.pdweb').forEach(item => !isMobile && item.classList.add('show'));
        document.querySelectorAll('.pweb').forEach(item => !isMobile && !isActive && item.classList.add('show'));
        document.querySelectorAll('.puweb').forEach(item => !isMobile && isActive && item.classList.add('show'));
        const namePlan = isAnnual ? ANNUAL_PLAN : MONTHLY_PLAN;
        const pricePlan = isAnnual ? ANNUAL_PRICE : MONTHLY_PRICE;
        const initDate = format(data.buyTime, TIME_FORMAT);
        const startPaidDate = format(data.isTrial ? addDays(data.buyTime, DAYS_TRIAL) : data.buyTime, TIME_FORMAT);
        const paymentMethod = !isMobile ? WEB_SITE : data.paymentProcessor === 'itunes' ? APP_STORE : GOOGLE_PLAY;
        const notePlan = isActive ? `Payment will be debited: ${startPaidDate}` : 'Please, renew your subscription to enjoy Productive Premium.';
        div.innerHTML = `<div class="plan-info-top">
                         <div class="plan-info-top__plan">${namePlan}</div>
                         <div class="plan-info-top__status">Activated via ${paymentMethod} on ${initDate}</div>
                     </div>
                     <div class="plan-info-bot">
                          <div class="plan-info-bot__price ${!isActive && 'hidden'}">${pricePlan}</div>
                          <div class="plan-info-bot__note">${notePlan}</div>
                     </div>`;
        if (!isMobile) {
            parentWebNode.prepend(div);
            return;
        }
        parentMobileNode.prepend(div);
    });
};

const getSubscriptions = () => {
    authService.getBraintreeSubscriptions().then(response => {
        const { data } = response;
        let webSubs = [];
        if (data) {
            const expirationDate = getTime(parseISO(data[0].expirationDate));
            webSubs = [{
                data: {
                    paymentProcessor: 'braintree',
                    isTrial: data[0].price > 10,
                    transactionId: data[0].id,
                    buyTime: getTime(parseISO(data[0].createdAt.date)),
                    expirationTime: expirationDate
                },
                productId: data[0].price < 10 ? 'productive_web_main_06-99_01m_00dt' : 'productive_web_main_34-99_01y_07dt'
            }];
            if (data[0].status === 'Canceled') {
                webSubs[0].data.cancelReason = 'system';
            }
            if (isPast(expirationDate) && data[0].status === 'Canceled') {
                setFreeUser();
                clearPopup();
                return;
            }
        }
        const subscriptions = [...webSubs];
        setPaidUser(subscriptions);
        clearPopup();
    });
};

const popupDeleteModal = templateModal('popup-delete-user');
const deleteModal = () => {
    modalWindow(popupDeleteModal).show().once('dismiss', (modal, ev) => {
        if (ev.target.classList.contains('button_border-yellow')) {
            showPopup();
            authService.deleteUserAccount(() => {
                gtag('event', 'Profile - Delete account confirmed'); // eslint-disable-line no-undef
                setTimeout(() => {
                    clearPopup();
                    Router.navigate(ROUTES.INDEX);
                }, 300);
            });
        }
    });
};

const popupUnsubscribeModal = templateModal('popup-unsubscribe');
const unsubscribeModal = e => {
    const id = e.currentTarget.getAttribute('data-id');
    modalWindow(popupUnsubscribeModal).show().once('dismiss', (modal, ev) => {
        if (ev.target.classList.contains('button_border-yellow')) {
            showPopup();
            authService.deleteSubscription(id, () => {
                gtag('event', 'Profile - Subscription canceled'); // eslint-disable-line no-undef
                setTimeout(() => {
                    dropNodes();
                    getSubscriptions();
                    clearPopup();
                }, 300);
            });
        }
    });
};

export default path => {
    const priceListTemplate = templateRenderer('profile');
    return {
        [path]: {
            uses: () => {
                Router.updatePageLinks();
                showPopup();
                document.querySelector('.button_border-red').addEventListener('click', deleteModal);
                document.querySelector('.plan-unsubscribe button').addEventListener('click', (e) => unsubscribeModal(e));
                billingSDK.getPaymentPlans().then(
                    response => {
                        if (response.message) {
                            clearPopup();
                            Router.navigate(ROUTES.SIGNIN);
                        } else {
                            const name = document.querySelector('.card-info__user');
                            name.innerHTML = authService.getUserName(jwtDecode);
                            getSubscriptions();
                        }
                    });
            },
            hooks: {
                before: done => {
                    priceListTemplate.mount();
                    done();
                },
                leave: () => {
                    priceListTemplate.unmount();
                }
            }
        }
    };
};
