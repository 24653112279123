import { AuthSDK } from '@bendingspoons/mosaic-auth';

export class ProductiveAuthSDK extends AuthSDK {
    init(cb) {
        if (!this.ready) {
            this.invoke((authToken, refreshToken) => {
                this.authToken = authToken;
                this.refreshToken = refreshToken;
                // eslint-disable-next-line standard/no-callback-literal
                cb([authToken, refreshToken]);
            });
        } else {
            // eslint-disable-next-line standard/no-callback-literal
            cb([this.authToken, this.refreshToken]);
        }
    }

    clear() {
        super.clear();
        window.localStorage.removeItem('authToken');
        window.localStorage.removeItem('refreshToken');
    }

    store({ authToken, refreshToken }) {
        this.authToken = authToken;
        this.refreshToken = refreshToken;
        window.localStorage.setItem('authToken', authToken);
        window.localStorage.setItem('refreshToken', refreshToken);
    }

    invoke(cb) {
        const authToken = window.localStorage.getItem('authToken');
        const refreshToken = window.localStorage.getItem('refreshToken');
        cb(authToken, refreshToken);
    }

    // [TODO] server's response body is empty
    forgotPassword(email, cb) {
        // eslint-disable-next-line no-undef
        fetch(`${this.baseURL}/api/forgot/password`, {
            method: 'POST',
            headers: {
                'X-API-KEY': this.apiKey,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(email)
        }).then(() => cb());
    }

    // [TODO] server's response body is empty
    resetPassword({ password, token }, cb) {
        // eslint-disable-next-line no-undef
        fetch(`${this.baseURL}/api/reset/password`, {
            method: 'POST',
            headers: {
                'X-API-KEY': this.apiKey,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ password, token })
        }).then(() => cb());
    }

    getUserName(decode) {
        const token = decode(this.authToken);
        const { username } = token;
        return username || 'Productive user';
    }

    deleteUserAccount(cb) {
        // eslint-disable-next-line no-undef
        fetch(`${this.baseURL}/api/user`, {
            method: 'DELETE',
            headers: {
                'X-API-KEY': this.apiKey,
                Authorization: `Bearer ${this.authToken}`,
                'Content-Type': 'application/json'
            }
        }).then(() => {
            this.clear();
            cb();
        });
    }

    deleteSubscription(id, cb) {
        // eslint-disable-next-line no-undef
        fetch(`${this.baseURL}/api/payment/subscription/${id}`, {
            method: 'DELETE',
            headers: {
                'X-API-KEY': this.apiKey,
                Authorization: `Bearer ${this.authToken}`,
                'Content-Type': 'application/json'
            }
        }).then(() => cb());
    }

    getBraintreeSubscriptions() {
        // eslint-disable-next-line no-undef
        return fetch(`${this.baseURL}/api/payment/subscription`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${this.authToken}`,
                'X-API-KEY': this.apiKey
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Something went wrong');
        }).then(response => response).catch(error => {
            return error;
        });
    }
}
