import { addDays, format } from 'date-fns';
import Router from './createRouter';
import billingSDK from '../billing';
import ROUTES from './constants';
import { templateRenderer, clearPopup, showPopup, trialInfo } from '../utils';

const setPlanInfo = data => {
    const { price, trialDuration, id } = data;
    trialInfo(trialDuration, price);
    const button = document.querySelector('.button');
    button.setAttribute('href', `#payment/${id}`);
    const dueDate = `${trialDuration ? format(addDays(new Date(), trialDuration), 'dd MMMM, yyyy') : 'Today'}`;
    const node = document.querySelector('.subscription-info');
    while (node.firstChild) node.removeChild(node.firstChild);
    const div = document.createElement('div');
    div.innerHTML = `<div class="subscription-info-line"><div>Due ${dueDate}</div> <span class="price">$${price}</span></div>
    ${trialDuration
        ? `<div class="subscription-info-line">
        <div>Due Today (${trialDuration} days free)</div>
        <span class="price">$0.00</span></div>` : ''}`;
    node.prepend(div);
};

const getPlanInfo = data => {
    const { plans } = data;
    const radio = document.querySelectorAll('input[type="radio"]');
    const activeRadio = document.querySelector('input[type="radio"]:checked');
    let id = activeRadio.value;
    let activePlan = plans.find(item => item.id === id);
    radio.forEach(item => item.addEventListener('click', e => {
        id = e.target.value;
        activePlan = plans.find(item => item.id === id);
        setPlanInfo(activePlan);
    }));
    setPlanInfo(activePlan);
};

const showPlans = data => {
    const node = document.querySelector('.radio-group');
    const { plans } = data;
    for (let i = 0; i < plans.length; i++) {
        const div = document.createElement('div');
        div.className = 'radio';
        const id = plans[i].id;
        const price = `$ ${plans[i].price}`;
        const duration = plans[i].billingFrequency;
        const isAnnual = duration === 12;
        const namePlan = isAnnual ? 'Annual Plan' : 'Monthly plan';
        const perMonth = isAnnual ? '($2.92/month)' : '';
        const checked = isAnnual;
        div.innerHTML = `<input class="custom-radio" type="radio" id="id${i}" name="id" value="${id}" checked="${checked}">
            <label for="id${i}">
                <div class="radio-about ${isAnnual && 'save'}">
                    <div class="radio-about__circle"></div>
                    <div class="radio-about__tariff">
                        <span>${namePlan}</span>
                        <span>${price} <span>${perMonth}</span></span>
                    </div>
                </div>
            </label>`;
        node.prepend(div);
    }
    getPlanInfo(data);
};

export default path => {
    const priceListTemplate = templateRenderer('prices');
    return {
        [path]: {
            uses: () => {
                Router.updatePageLinks();
                showPopup();
                billingSDK.getPaymentPlans().then(
                    response => {
                        clearPopup();
                        if (response.message) {
                            Router.navigate(ROUTES.LOGIN);
                        } else {
                            const { data } = response;
                            showPlans(data);
                        }
                    });
            },
            hooks: {
                before: done => {
                    priceListTemplate.mount();
                    done();
                },
                leave: () => {
                    priceListTemplate.unmount();
                }
            }
        }
    };
};
