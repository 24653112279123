const preload = document.createElement('div');

export const showPopup = () => {
    const parentEl = document.querySelector('body');
    const el = preload;
    el.className = 'preload-popup';
    parentEl.append(el);
};

export const clearPopup = () => {
    const el = preload;
    el.remove();
};
