export default {
    INDEX: '',
    SIGNIN: 'signin',
    SIGNUP: 'signup',
    START: 'start',
    QUIZ: 'quiz',
    PRICES: 'prices',
    CONGRATS: 'congrats',
    RESET: 'reset',
    FORGOT: 'forgot',
    PAYMENT: 'payment',
    BILLING: 'billing',
    PROFILE: 'profile'
};
