import serialize from 'form-serialize';
import { OAuthIdentity, AuthContext } from '@bendingspoons/mosaic-auth';
import Router from './createRouter';
import authService from '../auth';
import billingSDK from '../billing';
import {
    templateRenderer,
    templateModal,
    submitValidate,
    validateForm,
    showPopup,
    clearPopup,
    togglePassword,
    renderConsent,
    getConsent,
    modalWindow
} from '../utils';
import ROUTES from './constants';

const popupFailedModal = templateModal('popup-failed');

const failedModal = () => {
    modalWindow(popupFailedModal).show();
};

const socialSignIn = (data) => {
    showPopup();
    const oAuthIdentity = new OAuthIdentity();
    oAuthIdentity.setIDToken(data.id_token);
    const context = new AuthContext();
    const contextValue = getConsent();
    context.setConsent(contextValue);
    authService.signInWithOAuth(data.type, oAuthIdentity, context)
        .then((response) => {
            if (response.message) {
                clearPopup();
                failedModal();
                gtag('event', 'Login failed');
            } else {
                if (response.isNewUser) {
                    gtag('event', 'Account created successfully');
                    gtag('event', 'signup',
                        {
                            eventCategory: 'purchases',
                            eventLabel: 'signup'
                        });
                } else {
                    gtag('event', 'Login successful');
                }
                billingSDK.getPaymentProfile().then(
                    response => {
                        clearPopup();
                        const { data } = response;
                        if (data?.payment_customer_id) {
                            Router.navigate(ROUTES.PROFILE);
                        } else {
                            Router.navigate(ROUTES.PRICES);
                        }
                    }
                );
            }
        });
};

export default path => {
    const loginTemplate = templateRenderer('signin');
    const formLoginTemplate = templateRenderer('signin-form');

    return {
        [path]: {
            uses: () => {
                Router.updatePageLinks();
                showPopup();
                authService.getRegion().then((response) => {
                    renderConsent(response);
                    clearPopup();
                });
                const buttonApple = document.querySelector('.card-auth-btn__apple');
                appleLoaded.promise.then(() => {
                    buttonApple.style.display = 'flex';
                    buttonApple.addEventListener('click', () => AppleID.auth.signIn());
                });
                document.addEventListener('AppleIDSignInOnSuccess', data => socialSignIn({ type: 'apple', id_token: data?.detail?.authorization?.id_token }));
                const googleBtnClick = () => {
                    gtag('event', 'Log in with Google clicked');
                };
                gpLoaded.promise.then(() => {
                    google.accounts.id.initialize({
                        client_id: '293284398754-rhgan0g4p75in6b73epei8edssova2ov.apps.googleusercontent.com',
                        callback: response => {
                            socialSignIn({ type: 'google', id_token: response.credential });
                        }
                    });
                    google.accounts.id.renderButton(
                        document.querySelector('.card-auth-btn__google'),
                        { theme: 'outline', size: 'large', logo_alignment: 'center', locale: 'en-US', width: 160, text: 'signin', shape: 'circle', click_listener: googleBtnClick }
                    );
                });
                const buttonFB = document.querySelector('.card-auth-btn__fb');
                fbLoaded.promise.then(() => {
                    FB.getLoginStatus((response) => {
                        if (response.status === 'connected') {
                            FB.logout();
                        }
                    });
                    buttonFB.style.display = 'flex';
                    buttonFB.addEventListener('click', loginFb);
                });
                function loginFb() {
                    FB.login((response) => {
                        if (response.status === 'connected') {
                            socialSignIn({ type: 'facebook', id_token: response.authResponse.accessToken });
                        } else {
                            console.log('error');
                        }
                    }, { scope: 'email' });
                }
            },
            hooks: {
                before: done => {
                    loginTemplate.mount();
                    done();
                },
                leave: () => {
                    loginTemplate.unmount();
                }
            }
        },
        [`${path}/form`]: {
            uses: () => {
                Router.updatePageLinks();
                const form = document.querySelector('form');
                showPopup();
                authService.getRegion().then((response) => {
                    renderConsent(response);
                    clearPopup();
                });
                const submitCallback = () => {
                    showPopup();
                    const data = serialize(form, {
                        hash: true
                    });
                    const { email, password } = data;
                    authService.signInWithEmailPassword({
                        username: email,
                        password
                    })
                        .then((response) => {
                            clearPopup();
                            if (response.message) {
                                gtag('event', 'Login failed');
                                failedModal();
                            } else {
                                gtag('event', 'Login successful');
                                authService.sendEmail({
                                    email,
                                    data_collection_consent: getConsent()
                                });
                                Router.navigate(ROUTES.PROFILE);
                            }
                        });
                };
                form.addEventListener('submit', (e) => {
                    e.preventDefault();
                    submitValidate(submitCallback);
                });
                const inputs = document.querySelectorAll('form input');
                inputs.forEach(item => item.addEventListener('input', () => validateForm('Log in')));
                const passwordEye = document.querySelector('.form-eye');
                passwordEye.addEventListener('click', e => togglePassword(e));
            },
            hooks: {
                before: done => {
                    formLoginTemplate.mount();
                    done();
                },
                leave: () => {
                    formLoginTemplate.unmount();
                }
            }
        }
    };
};
