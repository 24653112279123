const setContinueBtnActive = () => {
    const continueBtn = document.querySelector('.form-submit');
    continueBtn.removeAttribute('disabled');
};

const setContinueBtnDisabled = () => {
    const continueBtn = document.querySelector('.form-submit');
    continueBtn.setAttribute('disabled', 'disabled');
};

export const activateContinue = (hostedFields) => {
    hostedFields.on('notEmpty', function(event) {
        const listOfInputs = Object.values(event.fields);
        let countFields = 0;
        for (const i in listOfInputs) {
            const paymentInput = listOfInputs[i];
            for (const key in paymentInput) {
                if (key === 'isEmpty') {
                    if (paymentInput[key] === false) {
                        ++countFields;
                    }
                }
            }
        }
        const validInputsLength = document.querySelectorAll('.braintree-hosted-fields-valid').length;
        if (countFields === 5 && validInputsLength === 4) {
            setContinueBtnActive();
        } else {
            setContinueBtnDisabled();
        }
    });
};
